import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mapbox-gl/dist/mapbox-gl.css';
import { PostHogProvider} from 'posthog-js/react'

const postHog_options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const emailJs_options = {
    publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    privateKey: process.env.REACT_APP_EMAILJS_PRIVATE_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: ['foo@emailjs.com', 'bar@emailjs.com'],
      // The variable contains the email address
      watchVariable: 'userEmail',
    },
    limitRate: {
      // Set the limit rate for the application
      id: 'app',
      // Allow 1 request per 10s
      throttle: 10000,
    },
  };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <PostHogProvider 
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={postHog_options}
  >
    <App emailJs_options = {emailJs_options}/>
  </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
