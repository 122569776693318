import {React, useState} from 'react';
import IdLinkButton from './IdLinkButton';

// props = {buttons, selectList, redirectFn, setColdFluButton}
// buttons is buttons list
// selectList is {"keys joined with a": [redirectId, text], "else": 0}
function CoughCheckBox(props) {
   const [selectedIds, setSelectedIds] = useState([]);
   const redirectButtons = {};
   for (const [key, value] of Object.entries(props.selectList)) {
      if (key !== 'else') {
         redirectButtons[key] = (<IdLinkButton text={value[1]} redirect={value[0]} redirectFn={props.redirectFn}/>);
      }
    }
   const [redirectAnswers, setRedirectAnswers] = useState([]);

   const handleCheckboxChange = (event) => {
      const checkedId = event.target.value;
      if(event.target.checked){
         setSelectedIds([...selectedIds,checkedId])
      }else{
         setSelectedIds(selectedIds.filter(id=>id !== checkedId))
      }
   }

   const handleSubmit = async (e) => {
      e.preventDefault();
      selectedIds.sort();
      const key = selectedIds.join("a");
      if (key in redirectButtons) {
         setRedirectAnswers([redirectButtons[key]]);
         props.setColdFluButton(false);
      } else {
         const possibleRedir = []
         for (let id of selectedIds) {
            possibleRedir.push(redirectButtons[id]);
         }
         setRedirectAnswers(possibleRedir);
         props.setColdFluButton(true);
      }
   }
   
   
   const parseCheckboxes = (button, index) => {
      return (
         <label>
         <input type="checkbox" id={props.buttons[index]["selectId"]} value={props.buttons[index]["selectId"]} 
                onChange={(e) => handleCheckboxChange(e)} ></input>
         {props.buttons[index]["text"]}
         </label>
      );
   }

   console.log(redirectAnswers.length);
  return (
   <div className="CoughContainer">
   <form onSubmit={(e) => handleSubmit(e)}>
      {props.buttons.map(parseCheckboxes)}
    <br/>
    <br/>
    <input className="neumorphism-back-button" type="submit" value="Submit"></input>
    <div>
      {redirectAnswers.length === 1 ? 
         (<h3>Based on your answers above, press on the option below:</h3>) : ""
      }
      {redirectAnswers.length > 1 ? 
         (<h3 style={{"margin": "20px"}}>Based on your answers above, there are no combination products available, please start by selecting one of the options below:</h3>)
         : ""
      }
      {redirectAnswers}
    </div>
    </form>
   </div>
    
  )
}

export default CoughCheckBox;