import './PublicFacingHome.css';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Logo from '../assets/Logo.png';
import heroimg from '../assets/xpressselect_team_all.png';

function AboutUs(props) {
   
return (
   <div className='PublicFacingHome'> 
    <NavBar buttonText="Home Page" buttonTo="/"></NavBar>

    <div class="heroContactus">
    <img class="heroImgContactus" src={heroimg} alt="Hero turquoise"></img>
    <div className='aboutus'>
      <h1> <strong>About the Team</strong></h1>
      <p>
      Our team is made up of passionate individuals from diverse backgrounds in pharmacy, biology, computer science, engineering, and entrepreneurship. Led by co-founders who are Doctor of Pharmacy students at UBC, we bring deep expertise in pharmacy practice and a shared vision for healthcare innovation. We are supported by advisors with extensive experience in running health-related businesses and developing patient-facing software. Our goal is to combine clinical insight with innovative technology to redefine the pharmacy experience and make health solutions more accessible. 
      </p>
    </div>
    </div>

    <div className='content'>
    <div className='content-child'>
      <h2> <strong> Purpose:   </strong>  </h2>
      <p>
      At Xpress Select, our mission is to make pharmacy visits more efficient and stress-free. Founded by pharmacy students who saw the need for better access to over-the-counter solutions, we’re dedicated to empowering patients to make informed health decisions while saving time. The lack of time faced by pharmacists often results in added pressure on them or delays in patients getting timely answers about OTC products. This pain point was validated through discovery interviews we conducted during our market validation process, with 80% of pharmacists from the 78 pharmacies interviewed across Greater Vancouver confirming the issue.</p>
      <p>
      With the continuous expansion of pharmacists' scope of practice, especially in BC, pharmacists are taking on more responsibilities, leading to increased burnout and even less time for patient interactions. We believe technology is essential to reduce this burden, enabling pharmacists to focus on providing quality care while ensuring patients get the right support quickly and conveniently.
      </p>
    </div>
    
    <div className='content-child'>
    <h2> <strong> Contact Us:   </strong>  </h2>
    <p>We’d love to hear from you! For any questions or inquiries, feel free to reach out via our email, which will be updated on our site shortly. We’ll be happy to assist you with anything you need.</p>
    <img src={Logo} className="contact-us-logo" alt="Xpress Select Logo"></img>
    </div>
    </div>
    
    
    <Footer/>
   </div>)
}

export default AboutUs;