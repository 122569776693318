import { React, useState, useEffect } from 'react';
import './yesNoForm.css';

// props = { symptoms: listOfString, symptomValues: listOfBoolean, yesRID, noRID, onRedirect }
function YesNoForm(props) {
  // const [yesNoSelection, setYesNoSelection] = useState(props.symptomValues);
  const [selectedRows, setSelectedRows] = useState(props.symptomValues);
  const [noneSelected, setNoneSelected] = useState(true);
  const [error, setError] = useState('');

  // will change rows if symptomValues change
  useEffect(() => {
    setSelectedRows(props.symptomValues);
  }, [props.symptomValues]);

  const handleRowClick = (index, value) => {
    const newFormData = [...selectedRows];
    newFormData[index] = value;
    setSelectedRows(newFormData);
    setNoneSelected(false);
  };

  const handleNoneClick = () => {
    setNoneSelected(true);
    setSelectedRows(props.symptomValues);
  };

  const handleSubmit = (e) => {
    //TODO
    e.preventDefault();
    const hasSelectedRow = selectedRows.reduce((ac, x) => ac || x, false);
    if (noneSelected) {
      props.onRedirect(props.noRID);
    } else if (!hasSelectedRow) {
      setError('Please select at least one row.');
    } else {
      props.onRedirect(props.yesRID);
    }
  };

  return (
    <form className="YesNoForm" onSubmit={(e) => handleSubmit(e)}>
      <table>
        <thead>
          <tr>
            <th>Select all that apply:</th>
            <th>Selected?</th>
          </tr>
        </thead>
        <tbody>
          {props.symptoms.map((prompt, index) => (
            <tr
              key={index}
              className={selectedRows[index] ? 'highlightedYesNoRow':''}
              onClick={() => handleRowClick(index, !selectedRows[index])}
            >
              <td>{prompt}</td>
              <td className="SelectedCheckboxColumn"><input type='checkbox' checked={selectedRows[index]}></input></td>
            </tr>
          ))}
          <tr
              className={noneSelected ? 'highlightedYesNoRow':''}
              onClick={handleNoneClick}
            >
              <td> <strong> None of the Above </strong> </td>
              <td className="SelectedCheckboxColumn"><input type='checkbox' checked={noneSelected}></input></td>
            </tr>
        </tbody>
      </table>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className='yesNoSubmitContainer'>
        <input className='neumorphism-back-button' type="submit" value="Submit"></input>
      </div>
    </form>
  );
}

export default YesNoForm;
