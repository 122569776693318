// props = {}
import WaiverText from '../components/WaiverText';
import './WaiverPage.css';
import logo from '../assets/Logo.png';

function WaiverPage (props) {
  return (
    <div className='Home'>
    <br></br>
    <img src={logo} className="Logo" alt="Xpress Select Logo"/>
    <h1 className="welcomeTitle">Disclaimer and Waiver of Liability</h1>
    <div className="waiver-form-container">
      <WaiverText/>
      <div className='float-group' onClick={()=>window.location.href = "/questionaire"}>
            I agree to the terms and conditions
      </div>
    </div>
    <div style={{height:"40vh", display: "block"}}></div>
    <div className="gradient-background"></div>
    </div>
  );
};

 
 export default WaiverPage;
 