import React from 'react';
import CoughCheckBox from './CoughCheckBox';
import YesNoForm from './YesNoForm';
import Divider from './Divider';
import Buttons from './Buttons';  

//props = {data, onRedirect, setColdFluButton (optional handler)}
function BoxComponent(props) {
   const renderSwitch = (buttonType) => {
    switch(buttonType) {
      case 'MACS_Referral':
        return (<div class="loader"></div>)
      case 'coughSelectAllThatApply':
        return (<CoughCheckBox buttons= {props.data["buttons"]} 
                              selectList={props.data["selectList"]} 
                              redirectFn = {props.onRedirect}
                              setColdFluButton={props.setColdFluButton} />);
      case 'yesNo':
        return (<div className='questionaireBox'> <YesNoForm 
                           symptoms={props.data["yesNoQuestions"]}
                           symptomValues = {Array.apply(null, Array(props.data["yesNoQuestions"].length)).map(function () { return false})} 
                           yesRID={props.data["yesRID"]} noRID={props.data["noRID"]}
                           onRedirect={props.onRedirect}/> </div>);
      default:
        return (<Buttons buttonsData={props.data["buttons"]} 
                         buttonsType={props.data["type"]} 
                         isLeaf={props.data['isLeaf']}
                         onRedirect={props.onRedirect}/>)
    }
  };

   return (
    <div className='boxComponent'>
      <h3><pre className='h3_preTag'>{props.data["q"]}</pre></h3>
      <Divider/>
      {renderSwitch(props.data['type'])}
    </div>
    
   );  
}

export default BoxComponent;
