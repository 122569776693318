import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import headerLogo from '../assets/transparent512x512.png';
import heroImg from '../assets/alexander-grey-uk-no6Yv91g-unsplash_edited.png';
import kp_logo from "../assets/KerrisdalePharmacy-2023.webp";
import tpk_logo from "../assets/The-Pharmacy-Kitsilano-768x224.jpg";
import './PublicFacingHome.css';

function PublicFacingHome(props) {
  
  
  return (
   <div className="PublicFacingHome"> 
    <NavBar buttonText="About Us" buttonTo="/aboutus"></NavBar>
    <div className='hero-container'>
        <img className="heroImg" src={heroImg} alt="Hero Section - turquoise sparkles photographed by @sharonmccutcheon"></img>
        <div className='hero-text'>
        <h1 className='title'>Xpress <br/> Select</h1>

        <div className='hero-img-logo-container'>
        <img className='hero-img-logo' src={headerLogo} alt='Xpress Select logo Hero section' />
        
        </div>
       </div>
      </div>
    
    <div className="content">
    <div className="content-child">
      <p><strong>Our Services</strong></p>
      <p>Xpress Select enhances pharmacy operations by providing a digital platform that helps streamline over-the-counter (OTC) medication recommendations. Our B2B software guides patients through a series of questions about their symptoms and medical history to identify the most suitable product for them. This solution alleviates the workload on pharmacists, reduces wait times, and ensures patients receive timely, accurate advice—allowing pharmacy teams to focus on more critical tasks while improving overall service efficiency.</p>
    </div>
    
    <div className="content-child">
    <p><strong>Our Pharmacies</strong></p>
    <p>Currently, we are piloting our product with two customers: <br/> Kerrisdale Pharmacy and The Pharmacy Kitsilano. </p>
    <div> <img className='pharm-logo' src={kp_logo} alt=" Kerrisdale Pharmacy logo"></img> <img className='pharm-logo' src={tpk_logo} alt='The Pharmacy Kitsilano'></img> </div>
    </div> 
    
    </div>

    <Footer/>
  
    </div>
   
  );
}

export default PublicFacingHome;

