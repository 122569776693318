import React from 'react';
import Star from '../assets/icons/star.svg';

//props = {text, note, warning, redirect, redirectFn, id}
function IdLinkButton(props) {
  return (
    <button className='linkButton' onClick={() => props.redirectFn(props.redirect)}>
      <p className='linkButtonText'> <img src={Star} className='icon' alt=""></img> 
          {props.text}
      </p>
      {props.note && <p className='buttonNote'> {props.note} </p>}
      {props.warning && <p className='buttonWarning'> {props.warning} </p> }
    </button>
  )
}

export default IdLinkButton;