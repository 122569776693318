// props = {data}
// data = {type, report}
// type is one of "EMAILFAILED" "EMAILSUCEEDED"
function EmailPage(props) {
   const typeDependentWording = props.data["type"] === "EMAILSUCEEDED" ? 
         "The following report (ID #" + localStorage.getItem('MACS_email_count') + ") will be sent to the pharmacist for an assessment. Please meet them at the counter!" 
      :  "Please take a screenshot of the following report and show it to the pharmacist at the counter to help the pharmacist prescribe something for you.";
   const report = [];
   props.data["report"].forEach(element => {
      report.push(element);
      report.push((<br></br>))
   });

   return (
    <div className="Stack">
    <h3 className="h3_preTag"> {typeDependentWording} </h3>
    <p style={{"textAlign": "left"}}>
    {report}
    </p>
    </div>
   );
 }

 
 export default EmailPage;
 