import {React} from 'react';
import Star from '../assets/icons/star.svg';
import { usePostHog } from 'posthog-js/react';

//props = {text, note, warning, redirect, isLeaf: true or false}
function PageLinkButton(props) {
  const posthog = usePostHog();
  const posthogProduct = () => {
    if (props.isLeaf) {
      console.log('SymptomsAlgorithmEnd_productChosen');
      console.log(props.redirect);
      posthog?.capture('SymptomsAlgorithmEnd_productChosen', {"product_id": props.redirect, "product": props.text, "user": localStorage.getItem('verifiedAs')});
    }
    window.location.href = props.redirect;
  }

  return (
    <div>
    <button className='linkButton' onClick={() => posthogProduct()}>
      <p className='linkButtonText'> <img src={Star} className='icon' alt=""></img> 
          {props.text}
      </p>
      {props.note && <p className='buttonNote'> {props.note} </p>}
      {props.warning && <p className='buttonWarning'> {props.warning} </p> }
    </button>
    </div>
  );
}

export default PageLinkButton;