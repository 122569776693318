import React from 'react';
import PageLinkButton from './PageLinkButton';
import IdLinkButton from './IdLinkButton';
import HoverInformationButton from './HoverInformationButton';

//props = {buttonsData, buttonsType, onRedirect, isLeaf}
function Buttons(props) {

   const parseButtons = (button, index) => {
      if (props.buttonsType === 'map') {
        return (
          <li className="boxButtons">
            <PageLinkButton text={button["text"]} 
            redirect={button["RID"]} 
            id={index}
          />
        </li>
        );
      } else if (button['isGroup']) {
        return (
        <li className="boxButtons">
          <h2> {button["text"]} </h2> 
          {button["note"] && <p className='buttonNote'> {button["note"]} </p> }
          {button["warning"] && <p className='buttonWarning'> {button["warning"]} </p> }
        </li>);
      } else if (button['popUp']) {
        return (<li className="boxButtons">
          <HoverInformationButton
          text={button["text"]} note={button["note"]} warning={button["warning"]}
          imageSrc={button["RID"]}
          isLeaf={props.isLeaf}
          id={index}
          />
      </li>);
      }else if (button['isLink']) {
        return (<li className="boxButtons">
            <PageLinkButton
            text={button["text"]} note={button["note"]} warning={button["warning"]}
            redirect={button["RID"]}
            isLeaf={props.isLeaf}
            id={index}
            />
        </li>);
      } else {
        return (
          <li className="boxButtons">
              <IdLinkButton 
              text={button["text"]} note={button["note"]} warning={button["warning"]}
              redirect={button["RID"]} 
              redirectFn = {props.onRedirect}
              id={index}
              type={props.type}
              />
          </li>
        );
      }
    };

  return (
   <ul>
      {props.buttonsData.map(parseButtons)}
   </ul>
  )
}

export default Buttons;