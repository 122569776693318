// import {useState} from 'react';
import Star from '../assets/icons/star.svg';
import Popup from 'reactjs-popup';

//props = {text, note, warning, imageSrc, id}
const HoverInformationButton = (props) => {
  let imagePath;
  try {
    imagePath  = require(`../assets/xpressProductPhotos/${props.imageSrc}`);
  } catch {
    imagePath = '../assets/xpressProductPhotos/image_not_found.png';
  }
  console.log(imagePath);
  return (<div className="boxButtons">
    <Popup
    trigger={open => (
        <button className='hoverInformationButton'>
        <p className='linkButtonText'> <img src={Star} className='icon' alt=""></img> 
            {props.text}
        </p>
        {props.note && <p className='buttonNote'> {props.note} </p>}
        {props.warning && <p className='buttonWarning'> {props.warning} </p> }
      </button>
      )}
    position={['right center']}
    closeOnDocumentClick
  >
  <div className='popupContainer'> 
    <img className="popupImage" src={imagePath} alt={props.text} />
    <p>Please read packaging for specific dosing information.</p>
  </div>
  </Popup>
  </div>
);

}

// function HoverInformationButtonV1(props) {
//     const [hover, setHover] = useState(false);
//     const imagePath = require(`../assets/xpressProductPhotos/${props.imageSrc}`);
//   return (
//     <div>
//     {hover && 
//       //<img src={"../assets/xpressProductPhotos/" + props.imageSrc} alt={props.text}></img>
//       <img src={imagePath} alt="Displayed on hover" />
//     }
    
//     <button className='hoverInformationButton' 
//             // onMouseLeave={() => setHover(false)} 
//             onClick={() => setHover(!hover)}>
//       <p className='linkButtonText'> <img src={Star} className='icon' alt=""></img> 
//           {props.text}
//       </p>
//       {props.note && <p className='buttonNote'> {props.note} </p>}
//       {props.warning && <p className='buttonWarning'> {props.warning} </p> }
//     </button>
//     </div>
//   )
// }

export default HoverInformationButton;