import React from 'react';

const QuestionaireSideButton = ({ onClick, text}) => {
  return (
    <button className="neumorphism-back-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default QuestionaireSideButton;
