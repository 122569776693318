import {Link} from 'react-router-dom';
import headerLogo from '../assets/transparent512x512.png';
// props = {buttonText, buttonTo}
function NavBar(props) {
   return (
      <nav className="navbar">
      <div className="navbar-container">
      <img className="navbarLogo" src={headerLogo} alt='Xpress Select logo' />
      
      <div className="navbar-rightSide">
      <Link to={props.buttonTo} className='navbar-button-normal'>{props.buttonText}</Link>
      <Link to='/select' className="navbar-button">Pharmacy Access</Link>
      </div>
      </div>
      </nav>
   )
}

export default NavBar;