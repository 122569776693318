import { useState } from 'react';
import {getEmailTemplateId} from '../App';
import {Link} from 'react-router-dom';
import BoxComponent from '../components/BoxComponent';
import data from '../data.json';
import emailjs from '@emailjs/browser';
import EmailPage from './EmailPage';
import QuestionaireSideButton from '../components/QuestionaireSideButton';


//TODO THIS COMPONENT IS HORRIFIC 
//props = {emailJs_options, posthogObj}
function Questionaire(props) {
   const posthog = props.posthogObj;
   const [emailInit, setEmailInit] = useState(false);
   const [currId, setCurrId] = useState('1');
   const [algPath, setAlgPath] = useState([]);
   const [emailPage, setEmailPage] = useState(false); //{type: , report: }
   const [coldFluButton, setColdFluButton] = useState(false);

   //requires localStorage name to be saved
   const sendMACS_Report = (new_id) => {
    if (!emailInit) {
      emailjs.init(props.emailJs_options);
      setEmailInit(true);
    }
    const report = getAlgPathReport(algPath, currId, new_id);
    const emailsSent = localStorage.getItem('MACS_email_count');
    const maxEmails = localStorage.getItem('maximum_MACS_email_count');

    if (emailsSent >= maxEmails) {
      setEmailPage({"type": "EMAILFAILED", "report": report});
      console.log("MAX EMAILS EXCEEDED, DID NOT SEND REPORT");
      return;
    } else {
      localStorage.setItem('MACS_email_count', emailsSent + 1);
    } 

    const verifiedAs = localStorage.getItem('verifiedAs'); //TODO make actual patient name
    //for both email and emailFail what is your name? + identify a number receipt
    const email_report_info = {'from_name': verifiedAs, 'report_number': emailsSent, 'report': report.join('\n')};
    const template_id = getEmailTemplateId();
    
    // TODO: check posthog user and avoid sending spam
    // if email fails please show screen report of questions and answers
    emailjs
    .send('service_ff2ph58', template_id, email_report_info)
    .then(
      () => {
        console.log('EMAIL SUCCESS!');
        setEmailPage({"type": "EMAILSUCEEDED", "report": report});
      },
      (error) => {
        console.log('EMAIL FAILED...', error.text);
        console.log(error);
        setEmailPage({"type": "EMAILFAILED", "report": report});
      },
    );

   }

   const getSymptom = () => {
    for (let x of algPath) {
      if (data[x]['isStartingSymptom']) {
        return data[x]['isStartingSymptom'];
      }
    } 
    if (data[currId]['isStartingSymptom']) {
      return data[currId]['isStartingSymptom'];
    }
    return 'No starting symptom';
   } 

   const onRedirect = (new_id) => {
    console.log("was asked to redirect from curr to new: " + currId + " to " + new_id);
    if (currId === "1" ) {
      posthog?.capture('SymptomsAlgorithmStart', {"initialSymptom": new_id, "user": localStorage.getItem('verifiedAs')});
    } else if (data[new_id]['isLeaf']) {
      let startingSymptom = getSymptom();
      posthog?.capture('SymptomsAlgorithmEnded', {"finalState": new_id, "path": currId, "startingSymptom": startingSymptom, "user": localStorage.getItem('verifiedAs')});
    } 

    setAlgPath(algPath => [...algPath, currId]);
    setCurrId(new_id);

    if (data[new_id]['type'] === 'MACS_Referral') {
      posthog?.capture('MACS_referral_made', {"initialSymptom": new_id, "path": currId, "history": algPath, "user": localStorage.getItem('verifiedAs')});
      sendMACS_Report(new_id);
    }
   };

   const goBackOneQu = () => {
    if (algPath.length === 1) {
      window.location.href = '/select';
    } else {
      let last_id = algPath[algPath.length - 1];
      setAlgPath(algPath.slice(0, -1));
      //TODO coughApply NOT STABLE
      if (data[currId]["type"] === "coughSelectAllThatApply") {
        setColdFluButton(false);
      }
      setCurrId(last_id);
      setEmailPage(false);
    }
   };

   const goToColdFlu = () => {
    if (algPath.length < 1) {
      setAlgPath([]);
      setCurrId('1');
    } else {
      let i = algPath.length - 1;
      let id = algPath[i];
      while (data[id]["type"] !== "coughSelectAllThatApply"){
        id = algPath[--i];
      }
      if (data[id]["type"] === "coughSelectAllThatApply") {
        setColdFluButton(false);
      }
      setAlgPath(algPath.slice(0, i));
      setCurrId(id);
    }
   }

   if (!emailPage) {
    return (
      <div className='questionaireBox'>
        <div className="QuSideBar">
          <QuestionaireSideButton onClick={()=> window.location.href = '/select'} text="✮ Start a new recommendation"/>
          {currId !== "1" && <QuestionaireSideButton onClick={goBackOneQu} text="← Go Back"/>}
          {coldFluButton &&  <QuestionaireSideButton onClick={goToColdFlu} text="← Select another Symptom for Cold/Flu Page"/>}
        </div>
        <div className = "Stack">
            <BoxComponent data={data[currId]} onRedirect={onRedirect} setColdFluButton={setColdFluButton}/>
            {data[currId]['isLeaf'] &&
              <div> <Link className="finish-button" to={'/finish/' + String(currId)}>✮ Finish</Link> </div> 
            }
        </div>
      </div>
      );
   } else {
      return (
        <div className='questionaireBox'>
        <div className="QuSideBar">
          <QuestionaireSideButton onClick={()=> window.location.href = '/select'} text="✮ Start a new recommendation"/>
          {currId !== "1" && <QuestionaireSideButton onClick={goBackOneQu} text="← Go Back"></QuestionaireSideButton>}
        </div>
        <div className = "Stack">
          <EmailPage data={emailPage} />
          <br/>
          <div> <Link className="finish-button" to={'/finish/' + String(currId)}>✮ Finish</Link> </div> 
        </div>
        
        
      </div>
    );
   }
 }

// TODO: make as you go rather than continuous, so it's easier???
//  O(max len of path)
function getAlgPathReport(algPath, currId, new_id) {
  const currPath = JSON.parse(JSON.stringify(algPath));
  currPath.push(currId);
  currPath.push(new_id);
  let result = []
  let prev = 0;
  for (let i = 1; i < currPath.length; i++) {
    const linebreak = "______";
    let currNode = data[currPath[i]];
    if (prev === 0) {
      prev = i;
      result.push(linebreak);
      result.push('Q' + String(i)); 
      result.push(currNode["q"]);
    } else { //made selection from previous TODO coughAndCold will not work with this
      //push answer
      const array = getAnswerForQuestion(currPath[prev], currPath[i]);
      result = result.concat(array);
      prev = i;

      //push question      
      result.push(linebreak);
      result.push('Q' + String(i));
      result.push(currNode["q"]);
    }
  }
  return result;
 }

 function getAnswerForQuestion(prev_id, next_id) {
  const result = [];
  const answerblock = "Answer:";
  if (data[prev_id]['isLeaf']) {
    return ["Answer: Continue to next question"];
  }
  else if (data[prev_id]['type'] === 'yesNo') {
    for (let text of data[prev_id]['yesNoQuestions']) {
      result.push("-" + text);
    }
    
    result.push(answerblock);
    if (data[prev_id]['yesRID'] === next_id) {
      result.push('Yes');
    } else if  (data[prev_id]['noRID'] === next_id) {
      result.push('No');
    }
  } else {
    result.push(answerblock);
    let array = data[prev_id]['buttons'];
    for (let button of array) {
      if (button['RID'] === next_id) {
        result.push(button['text']);
        break;
      }
    } 
  }
  return result;
 }
 
 export default Questionaire;
 