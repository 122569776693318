import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useEffect} from 'react';
// import geoJson from "./hlbc_pharmacies.json";
import emergencies from "./hlbc_emergencyrooms.json";
import walk_in_clinic from "./hlbc_urgentandprimarycarecentres.json";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// function Map(props) {
//    const mapContainer = useRef(null);
//    const map = useRef(null);
//    const [lng, setLng] = useState(-123.12);
//    const [lat, setLat] = useState(49.28);
//    const [zoom, setZoom] = useState(12);

//    useEffect(() => {
//       if (map.current) return; // initialize map only once
//       map.current = new mapboxgl.Map({
//         container: mapContainer.current,
//         style: 'mapbox://styles/mapbox/streets-v12',
//         center: [lng, lat],
//         zoom: zoom
//       });
//    });
      
//    return (
//       <div>
//         <div ref={mapContainer} className="map-container" />
//       </div>
//    );
// }
// props = {features: "emergencies" or "walk_in_clinic"}
const Map = (props) => {
   const geoJson = props.features === "emergencies" ? emergencies : walk_in_clinic;
   const mapContainerRef = useRef(null);
 
   // Initialize map when component mounts
   useEffect(() => {
     const map = new mapboxgl.Map({
       container: mapContainerRef.current,
       style: "mapbox://styles/mapbox/streets-v12",
       center: [-123.12, 49.28],
       zoom: 10,
     });
 
     // Create default markers
     geoJson.features.map((feature) =>
       new mapboxgl.Marker().setLngLat(feature.geometry.coordinates).addTo(map)
     );
 
     // Add navigation control (the +/- zoom buttons)
     map.addControl(new mapboxgl.NavigationControl(), "top-right");
 
     // Clean up on unmount
     return () => map.remove();
   }, [geoJson]);
 
   return mapContainerRef ? (<div className="map-container" ref={mapContainerRef} />)
    : 
    (<div className='centerContainer'>
      <span className='loader'></span>
    </div>)
  ;
  };

export default Map;