import Logo from '../assets/Logo.png';
import {Link} from 'react-router-dom';
import Divider from '../components/Divider';
import PageLinkButton from '../components/PageLinkButton';

function Home(props) {
  return (
   <div className="Stack">
   <div>
   <img src={Logo} className="Logo" alt="Xpress Select Logo"></img>
   </div>
   <h1 className="welcomeTitle"> Welcome to Xpress Select! </h1>
      
   <div className='boxComponent'>

    <p style={{"padding": "0 15px", "fontSize": "large"}}><strong>Get a product recommendation:</strong></p>
    <p style={{"padding": "0 15px", "fontSize": "large"}}>Answer a few questions to find out which over-the-counter products might be best for you!</p>
    <div>
     <Link to='/waiver'> 
      <button className='startButton'>
          START 
      </button>
     </Link>
     <Divider/>
     <div>
        <PageLinkButton 
          text="If you are an Indigenous patient, click here to learn about fully covered products under Plan W."
          redirect='https://www2.gov.bc.ca/gov/content/health/practitioner-professional-resources/pharmacare/pharmacies/fnhb-plan-w-otc-drugs' 
          isLeaf={false} />
        <Link to='https://www2.gov.bc.ca/gov/content/health/practitioner-professional-resources/pharmacare/pharmacies/fnhb-plan-w-otc-drugs'> 
        <button className='planWButton'>
            Plan W options 
        </button>
        </Link>
     </div>
    </div>
    </div>
      <div>
      </div>
   </div>
  );
}

export default Home;
