import Logo from '../assets/Logo.png';
import { useState } from 'react';


// props = {verifyFn}
function AuthPage(props) {
   const [formValue, setFormValue] = useState("");
   const [wrongCode, setWrongCode] = useState(false);
   const handleChange = (e) => {
      setFormValue(e.target.value); 
   }
   const handleSubmit = () => {
      props.verifyFn({formValue});
      if (!localStorage.getItem('verifiedAs')) {
         setWrongCode(true);
      }
   }

  return (
   
   <div className='Home'>
   <h1 className="welcomeTitle"> Welcome to Xpress Select! </h1>
   <div>
      <img src={Logo} className="Logo" alt="Xpress Select Logo"></img>
   </div>
   <p> Please enter your pharmacy's access code to use: </p>
  
   <div className={wrongCode ? "wrongAuthCode": ""}>
   <input style={{"font-size": "x-large"}} type="text" name="authCode" value={formValue} onChange={handleChange} /> 
   {wrongCode && <p> Your input was incorrect.</p>}
   <br/>
   </div>
   <button className="neumorphism-back-button" onClick={handleSubmit}>Submit</button>
   
   </div>
  );
}

export default AuthPage;
