import Divider from '../components/Divider';
import './WrittenFeedbackPage.css';
import badImage from '../assets/bad_customerExp.png'; // Replace with your image path
import okImage from '../assets/ok_customerExp.png'; // Replace with your image path
import goodImage from '../assets/good_customerExp.png'; // Replace with your image path
import { useParams, Link } from "react-router-dom";
import {useState} from 'react';

// props = {posthogObj}
function FinishPage(props) {
   const posthog = props.posthogObj;
   const params = useParams(); // access params
   const finishId = params.finishId;
   const feedbackObj = {"finishId": finishId, "user": localStorage.getItem('verifiedAs')};
   const to = '/select';

   const [activeButton, setActiveButton] = useState('');
   const [comment, setComment] = useState('');

   const handleSubmit = (e) => {
     feedbackObj['comment'] = comment;
     console.log("did function enter");
     posthog?.capture('UserFeedbackComment', feedbackObj);
     console.log("doneposthog");
    }

   const handleClickFace = (userstate) => {
      setActiveButton(userstate);
      if (userstate === 'Bad') {
         feedbackObj['rating'] = 'Bad';
      } else if (userstate === 'Ok') {
         feedbackObj['rating'] = 'Ok';
      } else if (userstate === 'Good') {
         feedbackObj['rating'] = 'Good';
      }
   }

  return (
   <div className='Stack'>
   <div className='boxComponent'>
   <h2><pre className='h3_preTag'>Thanks for using Xpress Select!</pre></h2>
   <Divider/>
    <h3 style={{"margin": "15px"}}>Tap one of the faces below to let us know how we did:</h3>
    <div id="feedbackButtons">
         <div className={activeButton === 'Bad' ? 'feedback-face-glowing' : ''}>
            <button onClick={() => handleClickFace("Bad")} className="feedback-button">
            <img src={badImage} alt="Sad Face: “Disappointed”" />
            </button>
            <p>Disappointed</p>
         </div>
          <div className={activeButton === 'Ok' ? 'feedback-face-glowing' : ''}>
            <button onClick={() => handleClickFace("Ok")} className="feedback-button">
               <img src={okImage} alt="Neutral Face: “Indifferent”" />
            </button>
            <p>Indifferent</p>
          </div>
          <div className={activeButton === 'Good' ? 'feedback-face-glowing' : ''}>
               <button onClick={() => handleClickFace("Good")} className="feedback-button">
               <img src={goodImage} alt="Happy Face: “Satisfied” or “Happy”" />
               </button>
               <p>Happy</p>
          </div>
   </div>
   <div className="feedback-form-container">
   <form className="feedback-form">
   <h2>Leave Your Feedback</h2>
   <textarea
      className="feedback-textarea"
      placeholder="Enter your comments here..."
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      required
   ></textarea>
   
   <Link className="finish-button" to={to} onClick={handleSubmit}>Finish</Link>

   </form>
   </div>
   </div>

   </div>
  );
}

export default FinishPage;
