import React from 'react';

function Footer(props) {
  return (
    <footer className="footer">
    <div className='footer-container'>
    <div>
    <p><a href="/aboutus">About Us</a></p>
    </div>
    <div>
      <p>Copyright Xpress Select 2024</p>  
      <p>Photo by <a href="https://unsplash.com/@sharonmccutcheon?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Alexander Grey</a> on <a href="https://unsplash.com/photos/a-close-up-of-a-blue-and-white-background-uk-no6Yv91g?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></p>
    </div>
    </div>
    
    </footer>
  )
}

export default Footer;